// src/components/Total.tsx
import React from 'react';

interface TotalProps {
  total: number;
}

const Total: React.FC<TotalProps> = ({ total }) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <h2>Total: ${total}</h2>
    </div>
  );
};

export default Total;