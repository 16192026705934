// src/App.tsx
import React, { useState } from 'react';
import CardItem from './components/CardItem';
import Total from './components/Total';

interface Card {
  color: string;
  price: number;
  quantity: number;
}

const App: React.FC = () => {
  const initialCards: Card[] = [
    { color: 'Black', price: 2, quantity: 0 },
    { color: 'Navy', price: 2, quantity: 0 },
    { color: 'Aqua', price: 2, quantity: 0 },
    { color: 'Purple', price: 2, quantity: 0 },
    { color: 'Brown', price: 2, quantity: 0 },
    { color: 'Grey', price: 1, quantity: 0 },
    { color: 'Red', price: 3, quantity: 0 },
    { color: 'Double Action', price: 3, quantity: 0 },
  ];

  const [cards, setCards] = useState<Card[]>(initialCards);

  const handleIncrement = (index: number) => {
    const newCards = [...cards];
    newCards[index].quantity += 1;
    setCards(newCards);
  };

  const handleDecrement = (index: number) => {
    const newCards = [...cards];
    if (newCards[index].quantity > 0) {
      newCards[index].quantity -= 1;
    }
    setCards(newCards);
  };

  const total = cards.reduce((sum, card) => sum + card.price * card.quantity, 0);

  const handleDragStart = (index: number) => {
    const dragCard = cards[index];
    localStorage.setItem('dragCard', JSON.stringify(dragCard));
  };

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLTableRowElement>, index: number) => {
    event.preventDefault();
    const dragCard = JSON.parse(localStorage.getItem('dragCard') || '');
    const newCards = [...cards];
    newCards.splice(index, 0, dragCard);
    setCards(newCards);
    localStorage.removeItem('dragCard');
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Bingo Card Sales</h1>
      <table>
        <thead>
          <tr>
            <th>Colour and Price</th>
            <th>Subtract</th>
            <th>Quantity</th>
            <th>Add</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card, index) => (
            <tr
              key={card.color}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={(event) => handleDrop(event, index)}
            >
              <td style={{ padding: '15px', backgroundColor: card.color }}>{`${index + 1}. ${card.color} - $${card.price}`}</td>
              <td style={{ padding: '15px' }}>
                <button style={{ fontSize: '1.2em' }} onClick={() => handleDecrement(index)}>-</button>
              </td>
              <td style={{ padding: '15px' }}>{card.quantity}</td>
              <td style={{ padding: '15px' }}>
                <button style={{ fontSize: '1.2em' }} onClick={() => handleIncrement(index)}>+</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Total total={total} />
    </div>
  );
};

export default App;